import { Route, Routes as Rts } from "react-router-dom";
import SignedInRoute from "./auth/SignedInRoute";
import PrivateRoute from "../components/auth/PrivateRoute";
import LandingPage from "../pages/external/landing-page/LandingPage";
import EventPage from "../pages/external/event-page/EventPage";
import SignInPage from "../pages/auth/SignInPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ForbiddenPage from "../pages/error/403ForbiddenPage";
import NotFoundPage from "../pages/error/404NotFoundPage";
import DashboardPage from "../pages/internal/DashboardPage/DashboardPage";

export default function Routes() {
  return (
    <Rts>
      {/* External Pages */}
      <Route index element={<LandingPage />} />
      <Route path="event" element={<EventPage />} />
      <Route path="sign-in" element={<SignInPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />

      {/* Internal Pages */}
      <Route
        path="dashboard"
        element={
          <SignedInRoute>
            <DashboardPage />
          </SignedInRoute>
        }
      />
      {/* <Route
        path="signin"
        element={
          <SignedInRoute>
            <SignInPage />
          </SignedInRoute>
        }
      />
      <Route
        path="your-candidates"
        element={
          <PrivateRoute requiredGroups={["counselor", "admin"]}>
            <YourCandidatesPage />
          </PrivateRoute>
        }
      /> */}

      {/* Misc Routes */}
      <Route path="forbidden" element={<ForbiddenPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Rts>
  );
}
