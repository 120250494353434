import { Link } from "react-router-dom";

export default function ForbiddenPage() {
  return (
    <main className="grid h-screen place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-tertiary">403</p>
        <h1 className="mt-4 text-3xl font-bold text-gray-900 sm:text-5xl">
          Forbidden
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          You do not have access to view this page.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-tertiary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tertiary"
          >
            Go back home
          </Link>
          <a
            href="mailto:support@sakeenamatch.com"
            className="text-sm font-semibold text-tertiary"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
