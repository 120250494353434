import logo from "../../../images/mark.svg";
import blankProfilePhoto from "../../../images/blank-profile-picture.jpg";
import { useState } from "react";
import {
  Bars3Icon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getAuth } from "firebase/auth";
import { useIdToken, useSignOut } from "../../../hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";

const navigation = [
  { name: "My Events", href: "#", current: true },
  { name: "Team", href: "#", current: false },
  { name: "Analytics", href: "#", current: false },
];

const userNavigation = [
  { name: "My Profile", href: "#" },
  { name: "Settings", href: "#" },
];

const tableHeaders = [
  { name: "Details" },
  { name: "Status" },
  { name: "Email" },
];

const events = [
  {
    title: "Youth Night 1445",
    code: "0313",
    start_date: 1712970000,
    end_date: 1713079174,
    status: "active",
  },
  {
    title: "Mawlid Imam Mahdi (aj)",
    code: "110",
    start_date: 1677981600,
    end_date: 1713079174,
    status: "active",
  },
  {
    title: "Muharram Late Night Sesh",
    code: "7860",
    start_date: 1712970000,
    end_date: 1713079174,
    status: "active",
  },
  {
    title: "Test",
    code: "123456",
    start_date: 1712970000,
    end_date: 1713079174,
    status: "active",
  },
  {
    title: "Youth Night 1442",
    code: "0257",
    start_date: 1712970000,
    end_date: 1713079174,
    status: "active",
  },
  {
    title: "Mawlid Imam Hussain (as)",
    code: "114",
    start_date: 1712970000,
    end_date: 1713079174,
    status: "active",
  },
];

export default function DashboardPage() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, loading, error] = useIdToken(auth);
  const [signOut, loadingSignOut, signOutError] = useSignOut(auth);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  if (error) {
    navigate("/error");
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  // Event handler for sign out
  const handleSignOut = async (event: any) => {
    const success = await signOut();
    if (success) {
      navigate("/sign-in");
    }
  };

  return (
    <>
      {/* start:Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden absolute mobile-menu overflow-none flex w-full">
          <div className="pt-16 h-screen w-3/4 bg-green-900">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <Link
                  to={item.href}
                  key={item.name}
                  className={classNames(
                    item.current
                      ? "bg-green-950 text-white"
                      : "text-white hover:bg-green-950 hover:bg-opacity-75",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="border-t border-l-green-950 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user?.photoURL || blankProfilePhoto}
                    alt={user?.displayName || ""}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {user?.displayName}
                  </div>
                  <div className="text-xs font-medium text-green-100">
                    {user?.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-green-950 hover:bg-opacity-75"
                  >
                    {item.name}
                  </Link>
                ))}
                <button
                  onClick={handleSignOut}
                  className="w-full block rounded-md px-3 py-2 text-base font-medium text-white text-left hover:bg-green-950 hover:bg-opacity-75"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={() => setMenuOpen(false)}
            className="w-1/4 h-screen bg-opacity-80 bg-gray-500"
          ></div>
        </div>
      )}
      {/* <!-- end:Mobile Menu */}

      <div className="flex flex-col h-screen">
        {/* <!--  start::Header */}
        <header className="px-4 sm:px-6 lg:px-8 flex justify-between bg-green-900 h-16 text-white items-center">
          <div className="flex">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="md:inline-flex relative items-center justify-center rounded-md bg-green-900 p-2 text-white hover:bg-green-950  hover:bg-opacity-75 hover:text-green-100"
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              {menuOpen ? (
                <ArrowLeftIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
            <div className="flex-shrink-0">
              <img className="h-10 w-10" src={logo} alt="Hejrat Foundation" />
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <div className="relative ml-3">
                <div>
                  <button
                    onClick={() => setUserMenuOpen(!userMenuOpen)}
                    className="m-2 relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-950"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={user?.photoURL || blankProfilePhoto}
                      alt={user?.displayName || ""}
                    />
                  </button>
                </div>
                {userMenuOpen && (
                  <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                    <button
                      onClick={handleSignOut}
                      className="w-full block px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        {/* <div className="flex bg-pink-300 h-8 items-center">
          Full Width Subheader
        </div> */}
        {/* <!--  end::Header */}
        <div className="flex flex-1 overflow-hidden">
          {/* start::Sidebar */}
          {menuOpen && (
            <aside className="hidden sm:block bg-white w-56 overflow-y-auto border-r-2">
              <ul>
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700"
                  >
                    {item.name}
                  </Link>
                ))}
              </ul>
            </aside>
          )}
          {/* end::Sidebar */}
          {/* start::Main Content */}
          <main className="mx-4 md:mx-0 bg-gray-100 flex flex-1 overflow-y-auto">
            <div className="flex flex-col justify-between w-full">
              <div className="mx-auto py-6 sm:px-6 lg:px-8 w-full max-w-[1140px]">
                <div className="mt-4 flex items-center">
                  <div className="flex-auto">
                    <h1 className="text-2xl font-bold leading-6 text-gray-900">
                      My Events
                    </h1>
                  </div>
                  <div className="sm:ml-16 sm:mt-0 flex-none">
                    <button
                      type="button"
                      className="block rounded-md bg-green-900 px-3 py-2 text-center text-xs font-semibold text-white shadow-sm hover:bg-green-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      + Create Event
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-my-2 overflow-x-auto lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300 rounded-md">
                        <thead className="bg-gray-50">
                          <tr>
                            {tableHeaders.map((header) => (
                              <th
                                key={header.name}
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {header.name}
                              </th>
                            ))}
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {events.map((event) => (
                            <tr key={event.title} className="even:bg-gray-50">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-3">
                                <span className="font-medium text-sm">
                                  {event.title}
                                </span>
                                <span className="pl-2 text-gray-500">
                                  (#{event.code})
                                </span>
                                <br />{" "}
                                <span className="">
                                  {new Date(event.start_date * 1000).toString()}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {event.status}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {event.status}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm">
                                <PencilSquareIcon
                                  className="inline-flex w-4 h-4"
                                  aria-hidden="true"
                                />
                                <EllipsisVerticalIcon
                                  className="inline-flex ml-2 w-4 h-4"
                                  aria-hidden="true"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="mb-2 text-center">
                <p className="text-[0.75em]">
                  &copy; Hejrat Foundation | All Rights Reserved
                </p>
                <p className="text-[0.5em]">
                  These efforts are all dedicated to Imam Mahdi (aj), please
                  come back
                </p>
              </footer>
            </div>
          </main>
          {/* end::Main Content  */}
        </div>
      </div>
    </>
  );
}
