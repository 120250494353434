import { FormEvent, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { ArrowRightIcon, HashtagIcon } from "@heroicons/react/20/solid";
import { type Container, type ISourceOptions } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { HomeIcon, UserIcon } from "@heroicons/react/24/outline";

export default function LandingPage() {
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    // console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fullScreen: { enable: true, zIndex: -1 },
      particles: {
        number: {
          value: 2,
        },
        color: {
          value: "#cccccc",
        },
        shape: {
          type: "circle",
        },
        opacity: {
          value: 0.1,
        },
        size: {
          value: {
            min: 300,
            max: 800,
          },
        },
        move: {
          enable: true,
          speed: 0.5,
          outModes: "bounce",
        },
      },
    }),
    []
  );

  // Event handler for form submission
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (code === "" || code === undefined || code === null) {
      setError("That code is invalid");
      return;
    } else {
      navigate("/event");
    }
  };

  return (
    <div className="flex flex-col min-h-dvh">
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      )}

      <header className="flex p-4">
        <div>
          <a href="https://hejrat.org">
            <button
              type="button"
              className="rounded-full p-1.5 text-gray-500 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-900"
            >
              <HomeIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </a>
        </div>
        <div className="flex-1">
          <h6 className="font-bold leading-7 text-center text-green-900">
            Q&A
          </h6>
        </div>
        <div>
          <Link to="/sign-in">
            <button
              type="button"
              className="rounded-full p-1.5 text-gray-500 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-900"
            >
              <UserIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Link>
        </div>
      </header>

      <div className="mt-[37dvh] mx-auto grow max-w-[400px]">
        <div className="relative w-full min-w-[350px] max-w-[24rem] align-middle">
          <form onSubmit={handleSubmit}>
            <button
              type="submit"
              className="absolute w-8 h-8 right-2 top-0 bottom-0 my-auto rounded-full text-sm text-center font-semibold bg-green-900 hover:bg-green-800 z-10"
            >
              <ArrowRightIcon
                className="m-auto h-5 w-5 text-white"
                aria-hidden="true"
              />
            </button>
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-10">
              <HashtagIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="number"
              name="code"
              id="code"
              className={`block w-full rounded-full ${
                error ? "border-2 border-red-600" : "border-0"
              } py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-900 sm:text-sm sm:leading-6 drop-shadow-lg`}
              placeholder="enter code here"
              onChange={(e) => setCode(e.target.value)}
            />
          </form>
        </div>
        {error && (
          <p className="mt-2 text-center text-sm text-red-600">{error}</p>
        )}
      </div>

      <footer className="mb-4">
        <p className="text-center text-[0.7em] leading-5 text-gray-500">
          &copy; {new Date().getFullYear()} Hejrat Foundation | All rights
          reserved
        </p>
        <p className="text-center text-[0.5em] text-gray-400">
          These efforts are all dedicated to Imam Mahdi (aj), please come back
        </p>
      </footer>
    </div>
  );
}
