import { Navigate } from "react-router-dom";
import { useAuthState } from "../../hooks/auth";
import { getAuth } from "firebase/auth";
import Loading from "../Loading";

type Props = {
  requiredGroups?: string[];
  children?: React.ReactNode;
};

const SignedInRoute: React.FC<Props> = ({ children }) => {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    return <Loading />;
  }

  if (error) return <Navigate to="/error" />;

  if (!user) return <Navigate to="/sign-in" />;

  return <>{children}</>;
};

export default SignedInRoute;
